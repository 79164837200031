import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import FooterScript from "../components/footer-script";
import axios from "axios";


const ProgramsProfessionals = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organization_name, setOrganization] = useState("");
  const [description, setDescription] = useState("");
  const [showconfirmation, setShowConfirmation] = useState("No");
  const [isFetching, setisFetching] = useState(false);


  const submitForm = (event) => {
    setisFetching(true);
    event.preventDefault();
    axios
      .post("https://api.acolabz.com/askrishna/contactus", {
        full_name,
        organization_name,
        phone,
        email,
        description,
      })
      .then(data => {

        setisFetching(false);
        setShowConfirmation("Yes");
        alert("Successfull Sumit Your Request");
        setFullName("")
        setEmail("")
        setPhone("")
        setOrganization("")
        setDescription("")
      })
  };


  return (
    <>
      <SEO
        title='Home | Telentizo'
        description=''
        keywords=''
      />
      <Header activelink='programs' />

      <>
      <section className="hero">

<div className="container-fluid">
  <div className="hero-content">

    <div className="hero-infos">
      <div className="hero-info-wrap">
        <div className="hero-raw-info">
          <h2
            data-w-id="2db43db6-2169-6b3b-913b-34ad97237a00"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d"
            }}
            className="hero-title heading-01 black-text"
          >
            Discover Our AI Programs
          </h2>
          <div
            data-w-id="b4f59bef-62a7-ae9b-0b78-446d3f05b130"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d"
            }}
            className="hero-info-text body-01 black-text"
          >
           Talentizo offers a range of comprehensive AI training programs designed to cater to different age groups and professional needs. Our programs aim to equip individuals with the knowledge and skills necessary to excel in an AI-driven world.
          </div>
          <a href="/contact"className="button-01 w-button" >
            Contact Us
          </a>
        </div>
      </div>
      <div className="hero-thumbs">
        <div className="hero-bgs-wrap">
          <div className="hero-bgs-01 _01" />
          <div className="hero-bgs-01 _02" />
        </div>
        <div
          data-w-id="6d4fb424-27a2-c8db-13b8-bcc7296ce72d"

          className="hero-thumbb"
        >
          <img
           src="images/services.webp"
            loading="lazy"
            alt=""
            className="hero-thumb-img"
          />
        </div>
      </div>
    </div>

  </div>
</div>
</section>



        <section className="content-04" id="custom">
          <div className="container-fluid">
            <div className="content-wrap-04">
              <h2 class="conten-title-04 heading-03 black-text">Programs for Professionals</h2>

              <p><br />
              Our professional programs are designed for individuals at various career stages and roles. We offer courses on AI strategy for leadership, workplace AI tools for all cadres, and advanced AI development for IT professionals.</p>
              <div

                className="content-iteem-05 _01"
              >
                <div className="item-thumbs-03">
                  <img
                    src="/images/Professionals 2.jpg"
                    loading="lazy"
                    alt=""
                    className="item-thumb-img-03"
                  />
                </div>
                <div className="item-info-03 _04">

                  <h2 className="item-info-title-03 heading-04 black-text">
                    Course Highlights
                  </h2>
                  <div className="content-items-01 _01">
                    <div className="content-info-item-01 _01">

                      <div className="info-item-text-01 body-01 black-text">
                        <ul className="ullist">
                          <li><b>AI Strategy and Leadership: </b> Training leaders to develop and implement effective AI strategies within their organizations.</li>
                          <li><b>AI Tools for Business Efficiency: </b> Teaching professionals how to leverage AI tools to enhance productivity and efficiency.</li>
                          <li><b>Advanced AI Development: </b> Specialized courses for IT professionals focused on advanced AI development and implementation.</li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                  <h2 className="item-info-title-03 heading-04 black-text">
                  Benefits
                  </h2>
                  <div className="content-items-01 _01">
                    <div className="content-info-item-01 _01">

                      <div className="info-item-text-01 body-01 black-text">
                        <ul className="ullist">
                          <li><b>Strategic AI Implementation:</b>Enabling leaders to drive AI initiatives and transform their organizations.</li>
                          <li><b>Increased Workplace Efficiency: </b>  Helping professionals utilize AI tools to streamline operations and processes.</li>
                          <li><b>Up-to-Date Skills for IT Professionals: </b>  Keeping IT professionals at the forefront of AI technology and development.</li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                  <a href="/contact" className="button-01 cta-b another w-button">
                    Contact Us for Students Programs
                  </a>
                </div>
              </div>


            </div>
          </div>
        </section>



      </>

      <section className="cta">
        <div className="container-fluid">
          <div className="cta-content">
            <h2

              className="cta-title"

            >
              We would love to hear from you! Whether you have a question about our programs, want to collaborate, or need more information, please reach out to us.

            </h2>
            <p>
            </p>
            <div className="cta-buttons"  >
              <a href="/contact" className="button-01 cta-b w-button">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  );
};

export default ProgramsProfessionals;