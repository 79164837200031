import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

import { BrowserRouter as Router, Routes, Route, Link}
    from 'react-router-dom';


import Header from './components/header';
import Footer from './components/footer';

import Home from './pages/index';
import About from './pages/about';
import Programs from './pages/programs';
import Contact from './pages/contact';
import ProgramsSchoolStudents from './pages/programs-school-students';
import ProgramsCollegeStudents from './pages/programs-colleges-students';
import ProgramsProfessionals from './pages/programs-professionals';


function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/about' element={<About/>} />
            <Route path='/programs' element={<Programs/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/programs-school-students' element={<ProgramsSchoolStudents/>} />
            <Route path='/programs-colleges-students' element={<ProgramsCollegeStudents/>} />
            <Route path='/programs-professionals' element={<ProgramsProfessionals/>} />
               
        </Routes>
      </Router>
      </HelmetProvider>
  );
  }
    
  export default App;
