import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import FooterScript from "../components/footer-script";
import axios from "axios";


const ProgramsSchoolStudents = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organization_name, setOrganization] = useState("");
  const [description, setDescription] = useState("");
  const [showconfirmation, setShowConfirmation] = useState("No");
  const [isFetching, setisFetching] = useState(false);


  const submitForm = (event) => {
    setisFetching(true);
    event.preventDefault();
    axios
      .post("https://api.acolabz.com/askrishna/contactus", {
        full_name,
        organization_name,
        phone,
        email,
        description,
      })
      .then(data => {

        setisFetching(false);
        setShowConfirmation("Yes");
        alert("Successfull Sumit Your Request");
        setFullName("")
        setEmail("")
        setPhone("")
        setOrganization("")
        setDescription("")
      })
  };


  return (
    <>
      <SEO
        title='Home | Telentizo'
        description=''
        keywords=''
      />
      <Header activelink='programs' />

      <>
      <section className="hero">

<div className="container-fluid">
  <div className="hero-content">

    <div className="hero-infos">
      <div className="hero-info-wrap">
        <div className="hero-raw-info">
          <h2
            data-w-id="2db43db6-2169-6b3b-913b-34ad97237a00"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d"
            }}
            className="hero-title heading-01 black-text"
          >
            Discover Our AI Programs
          </h2>
          <div
            data-w-id="b4f59bef-62a7-ae9b-0b78-446d3f05b130"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d"
            }}
            className="hero-info-text body-01 black-text"
          >
           Talentizo offers a range of comprehensive AI training programs designed to cater to different age groups and professional needs. Our programs aim to equip individuals with the knowledge and skills necessary to excel in an AI-driven world.
          </div>
          <a href="/contact"className="button-01 w-button" >
            Contact Us
          </a>
        </div>
      </div>
      <div className="hero-thumbs">
        <div className="hero-bgs-wrap">
          <div className="hero-bgs-01 _01" />
          <div className="hero-bgs-01 _02" />
        </div>
        <div
          data-w-id="6d4fb424-27a2-c8db-13b8-bcc7296ce72d"

          className="hero-thumbb"
        >
          <img
           src="images/services.webp"
            loading="lazy"
            alt=""
            className="hero-thumb-img"
          />
        </div>
      </div>
    </div>

  </div>
</div>
</section>



        <section className="content-04" id="custom">
          <div className="container-fluid">
            <div className="content-wrap-04">
              <h2 class="conten-title-04 heading-03 black-text">Programs for School Students</h2>

              <p><br />
                Our AI programs for school students are integrated into the curriculum from classes 9 to 12. These courses are designed to build a strong foundation in AI and machine learning, encouraging curiosity and innovation among young minds.</p>
              <div

                className="content-iteem-05 _01"
              >
                <div className="item-thumbs-03">
                  <img
                    src="/images/School Students.jpg"
                    loading="lazy"
                    alt=""
                    className="item-thumb-img-03"
                  />
                </div>
                <div className="item-info-03 _04">

                  <h2 className="item-info-title-03 heading-04 black-text">
                    Course Highlights
                  </h2>
                  <div className="content-items-01 _01">
                    <div className="content-info-item-01 _01">

                      <div className="info-item-text-01 body-01 black-text">
                        <ul className="ullist">
                          <li><b>Foundations of AI and Machine Learning:</b> Introducing core AI concepts and basic machine learning techniques.</li>
                          <li><b>Practical AI Projects:</b> Hands-on projects that apply AI to real-world problems, fostering practical understanding.</li>
                          <li><b>AI Ethics and Impact:</b> Exploring the ethical considerations and societal impacts of AI technologies.</li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                  <h2 className="item-info-title-03 heading-04 black-text">
                  Benefits
                  </h2>
                  <div className="content-items-01 _01">
                    <div className="content-info-item-01 _01">

                      <div className="info-item-text-01 body-01 black-text">
                        <ul className="ullist">
                          <li><b>Early Exposure to AI Concepts:</b> Helping students gain an early understanding of AI principles and applications.</li>
                          <li><b>Enhanced Problem-Solving Skills:</b> Developing critical thinking and problem-solving abilities through AI projects.</li>
                          <li><b>Preparation for Advanced Studies:</b> Laying the groundwork for further studies in AI and related fields.</li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                  <a href="/contact" className="button-01 cta-b another w-button">
                    Contact Us for Students Programs
                  </a>
                </div>
              </div>


            </div>
          </div>
        </section>



      </>

      <section className="cta">
        <div className="container-fluid">
          <div className="cta-content">
            <h2

              className="cta-title"

            >
              We would love to hear from you! Whether you have a question about our programs, want to collaborate, or need more information, please reach out to us.

            </h2>
            <p>
            </p>
            <div className="cta-buttons"  >
              <a href="/contact" className="button-01 cta-b w-button">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  );
};

export default ProgramsSchoolStudents;