import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

export default function Header({ activelink }) {


  return (
    <>

      <header>
        <>
          {/* Hello world */}
          <section className="navigation">
            <div
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="navbar-component w-nav"
            >
              <div className="nav-container w-container">
                <a
                  href="/"
                  id="w-node-_65d5dcf8-aeb0-c01b-995f-25dd9a18ce48-9a18ce45"
                  className="op70 w-nav-brand"
                  aria-label="home"
                  style={{ opacity: 1 }}
                >
                  <img
                    loading="lazy"
                    src="/images/talentizo-logo.png"
                    alt="Website Logo"
                    className="website-brand-img"
                  />
                </a>
                <nav
                  role="navigation"
                  id="w-node-_65d5dcf8-aeb0-c01b-995f-25dd9a18ce4a-9a18ce45"
                  className="nav-menu-light w-nav-menu"
                >
                  <div
                    data-hover="true"
                    data-delay={0}
                    className="move-down-02 w-dropdown"
                    style={{ maxWidth: 1344 }}
                  >
                    <div
                      className="nav-link body-02 op70 w-dropdown-toggle"
                      id="w-dropdown-toggle-0"
                      aria-controls="w-dropdown-list-0"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      role="button"
                      tabIndex={0}
                      style={{ opacity: 1 }}
                    >
                      <div>
                        <Link to="/" className={`${activelink == 'home' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>Home</Link>

                      </div>

                    </div>

                  </div>
                  <div
                    data-hover="true"
                    data-delay={0}
                    className="move-down-02 w-dropdown"
                    style={{ maxWidth: 1344 }}
                  >
                    <div
                      className="nav-link body-02 op70 w-dropdown-toggle"
                      id="w-dropdown-toggle-1"
                      aria-controls="w-dropdown-list-1"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      role="button"
                      tabIndex={0}
                      style={{ opacity: 1 }}
                    >
                      <div>
                        <Link to="/about" className={`${activelink == 'about' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>About Us</Link>
                      </div>

                    </div>

                  </div>

                  <div
                    data-hover="true"
                    data-delay={0}
                    className="move-down-02 w-dropdown"
                    style={{ maxWidth: 1344 }}
                  >
                    <div
                      className="nav-link body-02 op70 w-dropdown-toggle"
                      id="w-dropdown-toggle-3"
                      aria-controls="w-dropdown-list-3"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      role="button"
                      tabIndex={0}
                      style={{ opacity: 1 }}
                    >
                      <div>
                        <Link to="/programs" className={`${activelink == 'programs' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}>Programs
                        <img src="/images/menu-arrow.svg" loading="lazy" alt="Caret Down" class="nav-down-arrow muneu-arrow" />
                        </Link>

                        
                      </div>
                      <nav
                        className="home-drop-list w-dropdown-list w--open"
                        id="w-dropdown-list-0"
                        aria-labelledby="w-dropdown-toggle-0"
                      >
                        <div className="home-drop-item">
                         
                          <a
                            href="/programs-school-students"
                            className="drop-item-link w-dropdown-link"
                            tabIndex={0}
                            style={{ opacity: 1 }}
                          >
                            School Students
                          </a>
                        </div>
                        <div className="home-drop-item opc">
                          
                          <a
                            href="/programs-colleges-students"
                            className="drop-item-link w-dropdown-link"
                            tabIndex={0}
                            style={{ opacity: 1 }}
                          >
                            College Students
                          </a>
                        </div>
                        <div className="home-drop-item opc last">
                          
                          <a
                            href="/programs-professionals"
                            className="drop-item-link w-dropdown-link"
                            tabIndex={0}
                            style={{ opacity: 1 }}
                          >
                             Professionals
                          </a>
                        </div>
                      </nav>


                    </div>

                  </div>
                  <div
                    data-hover="true"
                    data-delay={0}
                    className="move-down-02 w-dropdown"
                    style={{ maxWidth: 1344 }}
                  >
                    <div
                      className="nav-link body-02 op70 w-dropdown-toggle"
                      id="w-dropdown-toggle-4"
                      aria-controls="w-dropdown-list-4"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      role="button"
                      tabIndex={0}
                      style={{ opacity: 1 }}
                    >
                      <div>
                        <Link to="/contact" className={`${activelink == 'contact' ? 'nav-link nav__link active' : 'nav-link nav__link'}`}> Contact Us</Link>
                      </div>

                    </div>

                  </div>

                </nav>
                <div id="w-node-_65d5dcf8-aeb0-c01b-995f-25dd9a18ced2-9a18ce45" class="menu-button w-nav-button"
                  aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
                  <div class="icon w-icon-nav-menu">
                  </div>
                </div>
              </div>
              <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" />
            </div>
          </section>
        </>

      </header>

    </>
  );
};